.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

textarea::placeholder {
  color: black;
  opacity: 0.5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes fade-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out-pop-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
  }
}
@keyframes pulse-animation {
  50% {
    opacity: 0.5;
  }
}
@keyframes pop-animation {
  50% {
    transform: scale(1.1);
  }
}

.fade-pulse {
  animation-name: pulse-animation;
  animation-duration: 500ms;
}
.fade-in {
  animation-name: fade-animation;
  animation-duration: 500ms;
}
.fade-out {
  animation-name: fade-animation;
  animation-duration: 500ms;
  animation-direction: reverse;
}
.fade-out-pop {
  animation-name: fade-out-pop-animation;
  animation-duration: 500ms;
}

.main-window {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-header {
  display: flex;
  height: 2rem;
  background-color: aquamarine;
}

.gradient-background {
  background: linear-gradient(248deg, #85ffe3, #17a6e8, #2bc6bc);
  background-size: 180% 180%;
  animation: gradient-animation 6s ease infinite;
  transition: 0.3s;
}

.gradient-background-bottom {
  background: linear-gradient(120deg, #17a6e8, #2bc6bc);
  background-size: 180% 180%;
  animation: gradient-animation 6s ease infinite;
}

.gradient-background-btn-clear-all {
  background: linear-gradient(140deg, #e1341e, #e1341e, #380b0b);
  background-size: 120% 120%;
  animation: gradient-animation 1s ease infinite;
  transition: 0.3s;
}
/* //////////////////// DESKTOP //////////////////// */

@media screen and (min-width: 820px) {
  /* RE-FACTOR ALL CLASS NAMES TO THIS: */

  .app-window-parent {
    height: 90vh;
    /* width: 65vw; */
    max-width: 70rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 2%;
  }

  .app-window {
    height: 90vh;
    width: 65vw;
    max-width: 70rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border-radius: 1rem;
    overflow: auto;
    padding-bottom: 5rem;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .app-window::-webkit-scrollbar {
    display: none;
  }

  .top-row {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0.9);
    font-family: "Montserrat", "Roboto", sans-serif;
    font-size: 25px;
    font-weight: 300;
    text-transform: uppercase;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  }

  .bottom-row {
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .task-list-column-cont {
    display: flex;
    flex-direction: column;
    width: 75%;
  }

  .task-list-text-area-cont {
    margin: 2rem 0 0 0;
    width: 95%;
    border-radius: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }

  .task-list-text-area {
    padding: 0.5rem 0 0 1rem;
    border-radius: 1rem;
    height: 5rem;
    font-size: 25px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    width: 95%;
    border: none;
    background-color: aliceblue;
    resize: none;
    border: none;
    overflow: hidden;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .task-list-button-column-container {
    margin: 0 1rem 0 2rem;
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .task-list__button {
    margin: 2.5rem 0 0 0;
    height: 5rem;
    color: aliceblue;
    border-radius: 100rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-bottom-cont {
    margin-bottom: auto;
    width: 5rem;
    display: flex;
    flex-direction: column;
    margin: 1% 1% 1% 0;
  }

  .btn-bottom-menu {
    height: 5rem;
    background-color: aliceblue;
    color: aliceblue;
    border-radius: 100%;
    border: none;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 400;
    font-family: "Montserrat", "Roboto", sans-serif;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    transition: 0.3s;
  }

  .btn-bottom-menu:hover {
    /* border-radius: 30%; */
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.9);
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-container {
    margin: 0 0 8rem 0;
    padding: 0 1rem 0 1rem;
    height: 20%;
    width: 40%;
    max-width: 30rem;
    z-index: 100;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    width: 100%;

    font-weight: 500;
    font-family: "Montserrat", "Roboto", sans-serif;
    font-size: 16px;
  }

  .modal--close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 20%;
    cursor: pointer;
  }

  .lists__overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lists-container-parent {
    height: 90vh;
    width: 65vw;
    max-width: 70rem;
    z-index: 9;
    /* display: flex; */
    /* background-color: black; */
    transform: translate(0);
    pointer-events: none;
  }

  .lists-container {
    pointer-events: auto;
    margin: 0 0 8rem 0;
    padding: 1rem 1rem 0 1rem;
    /* height: 40%; */
    min-height: 40%;
    max-height: 60%;
    width: 40%;
    max-width: 30rem;
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.5);
    bottom: -0.5%;
    right: 2%;

    resize: none;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .lists-container::-webkit-scrollbar {
    display: none;
  }

  .lists-element-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .lists-element {
    margin: 0 0 1rem 0;
    padding: 0 0 0 0;
    width: 100%;
    background-color: aliceblue;
    border-radius: 0.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
  }

  .lists-element--shadow {
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.5);
    transition: 0.2s;
  }

  .lists-btn-container {
    display: flex;
  }

  .lists-name {
    border: none;
    background-color: aliceblue;
    outline: 0;
    width: 100%;
    padding: 0 0 0 10px;
  }

  .btn-lists-select,
  .btn-lists-delete {
    background-color: #17a6e8;
    /* border: 1px solid #077bf0; */
    border: none;
    color: white;
    margin: 0 10px 0 0;
    padding: 10px 5px 10px 5px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
  }

  .fa-trash,
  .fa-check {
    pointer-events: none;
  }

  .btn-list-new {
    margin: 0 0 1rem 0;
    color: #17a6e8;
    align-self: flex-start;
  }
}

/*///////////////////////////////// MOBILE ///////////////////////////////// */

@media screen and (max-width: 820px) {
  .app-window-parent {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
  }

  .app-window {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    /* background-color: #17a6e8; */
    border-radius: 1rem;
    overflow: auto;
    padding-bottom: 5rem;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .app-window::-webkit-scrollbar {
    display: none;
  }

  .top-row {
    /* border-top: #2bc6bc; */
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0.9);
    font-family: "Montserrat", "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  }

  .bottom-row {
    /* border-top: #2bc6bc; */
    box-shadow: 0 -1px 0px rgba(0, 0, 0, 0.1);
    width: 100vw;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: fixed;
    bottom: 0;
    /* margin: 5rem 0 0 0; */
  }

  .task-list-column-cont {
    /* margin: 2rem 0 0 0; */
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 75%;
  }

  .task-list-text-area-cont {
    margin: 1.5rem 0 0 0;
    width: 95%;
    /* background-color: aliceblue; */
    border-radius: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }

  .task-list-text-area {
    padding: 0.5rem 0 0 0.5rem;
    border-radius: 1rem;
    height: 4rem;
    font-size: 20px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    width: 95%;
    border: none;
    background-color: aliceblue;
    resize: none;
    border: none;
    overflow: hidden;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .task-list-button-column-container {
    margin: 0 0 0 0.5rem;
    /* width: 5%; */
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .task-list__button {
    margin: 2rem 0 0 0;
    height: 4rem;
    width: 3rem;
    /* background-color: aliceblue; */
    color: aliceblue;
    border-radius: 100rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-bottom-cont {
    margin-bottom: auto;
    margin: 1% 1% 1% 0;
  }

  .btn-bottom-menu {
    width: 4.5rem;
    height: 4.5rem;
    background-color: aliceblue;
    color: aliceblue;
    border-radius: 100%;
    border: none;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 400;
    font-family: "Montserrat", "Roboto", sans-serif;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    font-size: 10px;
    transition: 0.3s;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-container {
    margin: 0 0 5rem 0;
    /* padding: 0 1rem 0 1rem; */
    height: 40%;
    width: 90%;
    max-width: 30rem;
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }

  .modal-content {
    width: 100%;
    padding: 0 1rem 0 1rem;
    font-weight: 500;
    font-family: "Montserrat", "Roboto", sans-serif;
    font-size: 15px;
  }

  .modal--close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 20%;
    cursor: pointer;
  }

  .lists__overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lists-container-parent {
    height: 100vh;
    width: 100vw;
    pointer-events: none;
  }
  .lists-container {
    resize: none;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    pointer-events: auto;
    /* margin: 0 0 8rem 0; */
    padding: 1rem 1rem 0 0rem;
    min-height: 40%;
    max-height: 70%;
    width: 100%;
    max-width: 51rem;
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.5);
    bottom: 0;
  }

  .lists-container::-webkit-scrollbar {
    display: none;
  }

  .lists-element-container {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .lists-element {
    margin: 0 0 1rem 0;
    padding: 0 0 0 0;
    width: 100%;
    background-color: aliceblue;
    border-radius: 0.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
  }

  .lists-element--shadow {
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.5);
    transition: 0.2s;
  }

  .lists-btn-container {
    display: flex;
  }

  .lists-name {
    border: none;
    background-color: aliceblue;
    outline: 0;
    width: 100%;
    padding: 0 0 0 10px;
  }

  .btn-lists-select,
  .btn-lists-delete {
    background-color: #17a6e8;
    /* border: 1px solid #077bf0; */
    border: none;
    color: white;
    margin: 0 10px 0 0;
    padding: 10px 5px 10px 5px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
  }

  .btn-list-new {
    margin: 0 0 5% 5%;
    color: #17a6e8;
    align-self: flex-start;
  }
}
